import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/codefresh/volume/bolt/node_modules/gatsby-theme-docz/src/base/Layout.js";
import { useState, useRef, useEffect } from 'react';
import { Playground, Props } from 'docz';
import '../../dist/css/bolt-css.css';
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h1 {...{
      "id": "list-item"
    }}>{`List Item`}</h1>
    <Playground __position={0} __code={'() => {\n  const [lang, setLang] = useState(\'\')\n  //\n  const handleSelect = language => () => {\n    setLang(language)\n  }\n  return (\n    <div className=\"tw-flex tw-flex-col\">\n      <div\n        className={`tw-listitem ${\n          lang === \'en\' ? \'tw-listitem-selected\' : \'\'\n        }`}\n        onClick={handleSelect(\'en\')}\n      >\n        English\n      </div>\n      <div\n        className={`tw-listitem ${\n          lang === \'pt\' ? \'tw-listitem-selected\' : \'\'\n        }`}\n        onClick={handleSelect(\'pt\')}\n      >\n        Protuguese\n      </div>\n      <div\n        className={`tw-listitem ${\n          lang === \'es\' ? \'tw-listitem-selected\' : \'\'\n        }`}\n        onClick={handleSelect(\'es\')}\n      >\n        Spanish\n      </div>\n    </div>\n  )\n}'} __scope={{
      props,
      DefaultLayout,
      useState,
      useRef,
      useEffect,
      Playground,
      Props,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
  {() => {
        const [lang, setLang] = useState(''); //

        const handleSelect = language => () => {
          setLang(language);
        };

        return <div className="tw-flex tw-flex-col">
        <div className={`tw-listitem ${lang === 'en' ? 'tw-listitem-selected' : ''}`} onClick={handleSelect('en')}>
          English
        </div>
        <div className={`tw-listitem ${lang === 'pt' ? 'tw-listitem-selected' : ''}`} onClick={handleSelect('pt')}>
          Protuguese
        </div>
        <div className={`tw-listitem ${lang === 'es' ? 'tw-listitem-selected' : ''}`} onClick={handleSelect('es')}>
          Spanish
        </div>
      </div>;
      }}
    </Playground>
    <br />
    <br />
    <h2 {...{
      "id": "duas-linhas"
    }}>{`Duas Linhas`}</h2>
    <br />
    <Playground __position={3} __code={'() => {\n  const [lang, setLang] = useState(\'\')\n  //\n  const handleSelect = language => () => {\n    setLang(language)\n  }\n  return (\n    <div className=\"tw-flex tw-flex-col\">\n      <div className={`tw-listitem tw-listitem-multi tw-listitem-disabled`}>\n        <h3 className=\"tw-listitem-first\">Andy Harris</h3>\n        <p className=\"tw-listitem-second\">and@gmail.com</p>\n      </div>\n      <div\n        className={`tw-listitem ${\n          lang === \'pt\' ? \'tw-listitem-selected\' : \'\'\n        }`}\n        onClick={handleSelect(\'pt\')}\n      >\n        Protuguese\n      </div>\n      <div\n        className={`tw-listitem ${\n          lang === \'es\' ? \'tw-listitem-selected\' : \'\'\n        }`}\n        onClick={handleSelect(\'es\')}\n      >\n        Spanish\n      </div>\n    </div>\n  )\n}'} __scope={{
      props,
      DefaultLayout,
      useState,
      useRef,
      useEffect,
      Playground,
      Props,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
  {() => {
        const [lang, setLang] = useState(''); //

        const handleSelect = language => () => {
          setLang(language);
        };

        return <div className="tw-flex tw-flex-col">
        <div className={`tw-listitem tw-listitem-multi tw-listitem-disabled`}>
          <h3 className="tw-listitem-first">Andy Harris</h3>
          <p className="tw-listitem-second">and@gmail.com</p>
        </div>
        <div className={`tw-listitem ${lang === 'pt' ? 'tw-listitem-selected' : ''}`} onClick={handleSelect('pt')}>
          Protuguese
        </div>
        <div className={`tw-listitem ${lang === 'es' ? 'tw-listitem-selected' : ''}`} onClick={handleSelect('es')}>
          Spanish
        </div>
      </div>;
      }}
    </Playground>
    <br />
    <br />
    <h2 {...{
      "id": "secondary"
    }}>{`Secondary`}</h2>
    <br />
    <Playground __position={6} __code={'() => {\n  const [lang, setLang] = useState(\'\')\n  //\n  const handleSelect = language => () => {\n    setLang(language)\n  }\n  return (\n    <div className=\"tw-flex tw-flex-col\">\n      <div\n        className={`tw-listitem tw-listitem-secondary ${\n          lang === \'en\' ? \'tw-listitem-selected\' : \'\'\n        }`}\n        onClick={handleSelect(\'en\')}\n      >\n        English\n      </div>\n      <div\n        className={`tw-listitem tw-listitem-secondary ${\n          lang === \'pt\' ? \'tw-listitem-selected\' : \'\'\n        }`}\n        onClick={handleSelect(\'pt\')}\n      >\n        Protuguese\n      </div>\n      <div\n        className={`tw-listitem tw-listitem-secondary ${\n          lang === \'es\' ? \'tw-listitem-selected\' : \'\'\n        }`}\n        onClick={handleSelect(\'es\')}\n      >\n        Spanish\n      </div>\n    </div>\n  )\n}'} __scope={{
      props,
      DefaultLayout,
      useState,
      useRef,
      useEffect,
      Playground,
      Props,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
  {() => {
        const [lang, setLang] = useState(''); //

        const handleSelect = language => () => {
          setLang(language);
        };

        return <div className="tw-flex tw-flex-col">
        <div className={`tw-listitem tw-listitem-secondary ${lang === 'en' ? 'tw-listitem-selected' : ''}`} onClick={handleSelect('en')}>
          English
        </div>
        <div className={`tw-listitem tw-listitem-secondary ${lang === 'pt' ? 'tw-listitem-selected' : ''}`} onClick={handleSelect('pt')}>
          Protuguese
        </div>
        <div className={`tw-listitem tw-listitem-secondary ${lang === 'es' ? 'tw-listitem-selected' : ''}`} onClick={handleSelect('es')}>
          Spanish
        </div>
      </div>;
      }}
    </Playground>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      